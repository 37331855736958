import BaseBean from "@/utils/BaseBean";

export interface IPdfEngineDataObj {
    utilInst:PdfEngineUtil
    pdfEngineParams:any
    scale:number
}
export default class PdfEngineUtil extends BaseBean{
    public dataObj:IPdfEngineDataObj;

    constructor(proxy:any,dataObj:IPdfEngineDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    public initPdfEngineParams(pdfEngineParams:any):void{
        this.dataObj.pdfEngineParams = Object.assign({pageNum: 1,scale: 1,numPages: 0,loadAllPage:true,showScale:false,showTitle:true}, pdfEngineParams);
    }
}